import React from 'react'
import { navigate } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { LoginForm } from '../components/login-form'

import { useUserbaseUserState } from '../services/userbase/userProvider'

const LoginPage = () => {
  const { user, ready } = useUserbaseUserState()

  if (!ready) {
    // @TODO should be seeing splash screen
    return null
  }

  if (user) {
    // @TODO navigate to app
    navigate('/', { replace: true })
    return null
  }

  return (
    <Layout>
      <SEO title="Login" />
      <LoginForm>
        <h1 className="hd-xl text-blue-bright mb-24">Log In</h1>
      </LoginForm>
    </Layout>
  )
}

export default LoginPage
